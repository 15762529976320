import React from "react";

import EventOne from "../../assets/images/orions/tournament.jpg";
import EventTwo from "../../assets/images/orions/tournament-2.jpg";

function Events({ data: response, header }) {
  const { data } = response || { data: [] };
  return (
    <>
      <section className="orion_events">
        <div className="container mb-5 pb-5">
          <div className="section_title text-center pt-5 mb-5 mt-5">
            {header?.subTitle && (
              <span
                className="sub_text mb-1"
                data-aos="fade-down"
                data-aos-duration={1500}
              >
                {header?.subTitle}
              </span>
            )}
            {header?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {header?.title}
              </h2>
            )}
          </div>
          <div className="row no-gap mb-5">
            {data?.length > 0 &&
              data?.map((item, index) => (
                <div
                  key={item?.id}
                  className="col-md-6 position-relative orion_card"
                >
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                    alt={`OrionsEvent-${item.id}`}
                    id={`OrionsEvent-${item.id}`}
                    className="img-fluid"
                  />
                </div>
              ))}
          </div>
          <div className="text-center">
            <button className="btn btn_primary">View All</button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Events;
