import React from "react";
import { Link } from "react-router-dom";
import Banner1 from "../../assets/images/nassa/nassa-slider-1.jpg";
import Banner2 from "../../assets/images/nassa/nassa-slider-2.jpg";
import Banner3 from "../../assets/images/nassa/nassa-slider-3.jpg";

function Gallery2({ data }) {
  return (
    <>
      <section className="row_inner_am banner_card_section nassa_hero">
        <div className="container">
          <div className="row">
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div
                    className="blog_card"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    {/* <Link to="/menulistone" className="img img-rounded"> */}
                    <img
                      src={`${process.env.REACT_APP_UPLOAD_URL}${item}`}
                      alt="img"
                      className="img-fluid round"
                    />
                    {/* </Link> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Gallery2;
