import React from "react";

import BowlingImg from "../../assets/images/orions/bowling.jpg";
import AmbienceImg from "../../assets/images/orions/ambience.jpg";
import EntertainmentImg from "../../assets/images/orions/entertainment.jpg";

function AbtBanner({ data: response }) {
  const { data } = response || { data: [] };
  return (
    <>
      <section className="orion_highlight">
        {/* <div className="container"> */}
        <div className="row no-gap">
          {data?.length > 0 &&
            data?.map((item, index) => (
              <div
                className="col-md-4 position-relative orion_card"
                key={index}
              >
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}${item.imagePath}`}
                  alt={item.label}
                  className="img-fluid"
                />
                <div className="overlay-title text-capitalize">
                  {item.label}
                </div>
                <div
                  className="overlay-text"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            ))}
        </div>
        {/* </div> */}
      </section>
    </>
  );
}

export default AbtBanner;
