import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dishimg from "../../assets/images/dish.png";
import Dish07 from "../../assets/images/dish_07.png";
import Dish08 from "../../assets/images/dish_08.png";
import Dish09 from "../../assets/images/dish_09.png";
import Dish04 from "../../assets/images/dish_04.png";
import Dish05 from "../../assets/images/dish_05.png";
import Dish06 from "../../assets/images/dish_06.png";
import Dish01 from "../../assets/images/dish_01.png";
import Dish02 from "../../assets/images/dish_02.png";
import Dish03 from "../../assets/images/dish_03.png";
import Desert1 from "../../assets/images/desert1.png";
import Desert2 from "../../assets/images/desert2.png";
import Desert3 from "../../assets/images/desert3.png";
import Desert4 from "../../assets/images/desert4.png";

import Gallery2 from "../MenMenuList/Gallery2";

function MenuSection({ data }) {
  const [tabMenu, tabActive] = useState({ a: true });

  return (
    <>
      <section className="our_menu_section row_inner_am light_texchur">
        {/* <div className="side_shape">
          <img className="right_side" src={Dishimg} alt="img" />
        </div> */}
        <div className="container">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            {data?.subTitle && (
              <span className="sub_text">{data?.subTitle}</span>
            )}
            {data?.title && <h2>{data?.title}</h2>}
          </div>
          <ul
            className="nav nav-tabs"
            id="myTab"
            role="tablist"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            {data?.StellarBrandLabels.length > 0 &&
              data?.StellarBrandLabels?.map((item, index) => (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${tabMenu[item.id] && "active"}`}
                    onClick={() => tabActive({ [item.id]: true })}
                    id={`${item.id}-tab`}
                    data-toggle="tab"
                    to={`#${item.id}`}
                    role="tab"
                    aria-controls={`item.id`}
                    aria-selected="true"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}

            {/* <li className="nav-item">
              <Link
                className={`nav-link ${tabMenu.b && "active"}`}
                onClick={() => tabActive({ b: true })}
                id="orion-tab"
                data-toggle="tab"
                to="#orion"
                role="tab"
                aria-controls="orion"
                aria-selected="false"
              >
                orion's belt
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${tabMenu.c && "active"}`}
                onClick={() => tabActive({ c: true })}
                id="nassa-tab"
                data-toggle="tab"
                to="#nassa"
                role="tab"
                aria-controls="nassa"
                aria-selected="false"
              >
                nassa
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${tabMenu.d && "active"}`}
                onClick={() => tabActive({ d: true })}
                id="wishquilla-tab"
                data-toggle="tab"
                to="#wishquilla"
                role="tab"
                aria-controls="wishquilla"
                aria-selected="false"
              >
                wishquilla
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${tabMenu.e && "active"}`}
                onClick={() => tabActive({ e: true })}
                id="muses-tab"
                data-toggle="tab"
                to="#muses"
                role="tab"
                aria-controls="muses"
                aria-selected="false"
              >
                muses
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${tabMenu.f && "active"}`}
                onClick={() => tabActive({ f: true })}
                id="calliope-tab"
                data-toggle="tab"
                to="#calliope"
                role="tab"
                aria-controls="calliope"
                aria-selected="false"
              >
                calliope
              </Link>
            </li> */}
          </ul>
          <div
            className="tab-content"
            id="myTabContent"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            {data?.StellarBrandLabels.length > 0 &&
              data?.StellarBrandLabels?.map((item, index) => (
                <div
                  className={`tab-pane fade ${
                    tabMenu[item.id] && "show active"
                  }`}
                  id={`${item.id}`}
                  role="tabpanel"
                  aria-labelledby={`${item.id}-tab`}
                >
                  <div className="container">
                    {/* <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish07} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Tommy’s Margarita</h3>
                          <p>
                            Tomatoes Beef, Eggs, poached Beef, Eggs, poached,
                            with bacon, Bacon, chorizo, roasted roma{" "}
                          </p>
                          <span className="price">$18.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish08} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3> Meat steak raw marbled</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            with Galjoen fish Australian lungfish sea, bacon.{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish09} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Roasted roma tomatoes mushrooms &amp; spinach Beef,
                            Eggs, poached, fried, with bacon, chorizo.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish04} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            fried, with bacon. chorizo, roasted roma tomatoes.
                          </p>
                          <span className="price">$25.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish05} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Prickly Pear Tonic</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish06} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Bacon chorizo roasted</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                    <Gallery2 data={item?.StellarBrandLabelImages} />
                    <div className="text-center mt-5">
                      <Link to="#" className="btn btn_primary">
                        visit {item?.label}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            {/* <div
              className={`tab-pane fade ${tabMenu.b && "show active"}`}
              id="orion"
              role="tabpanel"
              aria-labelledby="orion-tab"
            >
              <div className="container"> */}
            {/* <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish01} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Beef, Eggs, poached, fried, with bacon, chorizo,
                            roasted roma tomatoes mushrooms &amp; spinach.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish02} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish03} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$18.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish04} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            fried, with bacon. chorizo, roasted roma tomatoes.
                          </p>
                          <span className="price">$25.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish05} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish06} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* <Gallery2 />
                <div className="text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    visit orion's belt
                  </Link>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tabMenu.c && "show active"}`}
              id="nassa"
              role="tabpanel"
              aria-labelledby="nassa-tab"
            >
              <div className="container"> */}
            {/* <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish07} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Tommy’s Margarita</h3>
                          <p>
                            Tomatoes Beef, Eggs, poached Beef, Eggs, poached,
                            with bacon, Bacon, chorizo, roasted roma{" "}
                          </p>
                          <span className="price">$18.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish08} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3> Meat steak raw marbled</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            with Galjoen fish Australian lungfish sea, bacon.{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish09} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Roasted roma tomatoes mushrooms &amp; spinach Beef,
                            Eggs, poached, fried, with bacon, chorizo.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish04} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            fried, with bacon. chorizo, roasted roma tomatoes.
                          </p>
                          <span className="price">$25.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish05} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Prickly Pear Tonic</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish06} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Bacon chorizo roasted</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* <Gallery2 />
                <div className="text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    visit nassa
                  </Link>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tabMenu.d && "show active"}`}
              id="whisquilla"
              role="tabpanel"
              aria-labelledby="whisquilla-tab"
            >
              <div className="container"> */}
            {/* <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Desert1} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Beef, Eggs, poached, fried, with bacon, chorizo,
                            roasted roma tomatoes mushrooms &amp; spinach.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Desert2} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Desert3} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$18.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Desert4} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            fried, with bacon. chorizo, roasted roma tomatoes.
                          </p>
                          <span className="price">$25.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish05} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish06} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* <Gallery2 />
                <div className="text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    visit whisquilla
                  </Link>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tabMenu.e && "show active"}`}
              id="muses"
              role="tabpanel"
              aria-labelledby="muses-tab"
            >
              <div className="container"> */}
            {/* <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish01} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Beef, Eggs, poached, fried, with bacon, chorizo,
                            roasted roma tomatoes mushrooms &amp; spinach.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish02} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish03} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$18.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish04} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            fried, with bacon. chorizo, roasted roma tomatoes.
                          </p>
                          <span className="price">$25.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish05} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish06} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* <Gallery2 />
                <div className="text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    visit muses
                  </Link>
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${tabMenu.f && "show active"}`}
              id="callipe"
              role="tabpanel"
              aria-labelledby="callipe-tab"
            >
              <div className="container"> */}
            {/* <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish07} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Tommy’s Margarita</h3>
                          <p>
                            Tomatoes Beef, Eggs, poached Beef, Eggs, poached,
                            with bacon, Bacon, chorizo, roasted roma{" "}
                          </p>
                          <span className="price">$18.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish08} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3> Meat steak raw marbled</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            with Galjoen fish Australian lungfish sea, bacon.{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <span className="new">NEW</span>
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish09} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Beef steak grill pan</h3>
                          <p>
                            Roasted roma tomatoes mushrooms &amp; spinach Beef,
                            Eggs, poached, fried, with bacon, chorizo.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish04} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>raw marbled meat steak</h3>
                          <p>
                            Shad angler arapaima pencilsmelt. Eggs, poached,
                            fried, with bacon. chorizo, roasted roma tomatoes.
                          </p>
                          <span className="price">$25.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish05} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Prickly Pear Tonic</h3>
                          <p>
                            Galjoen fish Australian lungfish sea, Shad angler
                            arapaima pencilsmelt. Eggs, poached, with bacon.
                          </p>
                          <span className="price">$16.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="dish_box">
                      <div className="dish_info">
                        <div className="dish_img">
                          <img src={Dish06} alt="img" />
                        </div>
                        <div className="dish_text">
                          <h3>Bacon chorizo roasted</h3>
                          <p>
                            Bacon, chorizo, roasted roma tomatoes Beef, Eggs,
                            poached Beef, Eggs, poached, with bacon,{" "}
                          </p>
                          <span className="price">$22.00</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* <Gallery2 />
                <div className="text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    visit callipe
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default MenuSection;
