import React, { useState, useRef } from "react";
import Slider from "react-slick";

import logoAtlas from "../../assets/images/brands/atlas-white.png";
import logoOrion from "../../assets/images/brands/orion-white.png";
import logoNassa from "../../assets/images/brands/nassa-white.png";
import logoWhisquilla from "../../assets/images/brands/whisquilla-white.png";
import logoStellar from "../../assets/images/brands/stellar-white.png";

// import bg1 from "../../assets/images/backgrounds/polo.jpg";
// import bg2 from "../../assets/images/backgrounds/bowling.jpg";
// import bg3 from "../../assets/images/backgrounds/polo.jpg";
// import bg4 from "../../assets/images/backgrounds/bowling.jpg";
// import bg5 from "../../assets/images/backgrounds/polo.jpg";

function PopularDish({ data: response }) {
  // const bgImages = [bg1, bg2, bg3, bg4, bg5, bg1, bg2, bg3, bg4, bg5];
  // const [currentSlide, setCurrentSlide] = useState(0);
  // const sliderRef = useRef(null);

  // const handleAfterChange = (currentSlideIndex) => {
  //   setCurrentSlide(currentSlideIndex % bgImages.length);
  // };

  const { data } = response;

  const sliderSettings = {
    slidesToShow: 5,
    arrows: false,
    margin: 70,
    autoplay: true,
    autoplaySpeed: 1500,
    centerMode: true,
    infinite: true,
    // beforeChange: handleAfterChange,
  };

  return (
    <>
      <section
        className="row_inner_am popular_dish_section"
        style={{ position: "relative", overflow: "hidden" }}
      >
        {/* <div
          className="overlay-image-container"
          style={{
            position: "absolute",
            top: 0,
            left: `${-currentSlide * 100}%`, // Sync image position with the slider
            width: `${bgImages.length * 100}%`, // Width enough to hold all images
            height: "100%",
            display: "flex",
            transition: "left 0.5s ease", // Smooth sliding effect
          }}
        >
          {bgImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`bg-${index}`}
              style={{
                width: `${100 / bgImages.length}%`,
                height: "100%",
                objectFit: "cover",
              }}
            />
          ))}
        </div> */}

        {/* Main content slider */}
        <Slider
          className="slider-dishes" //</section>ref={sliderRef}
          {...sliderSettings}
        >
          {data?.length > 0 &&
            data?.map((item, index) => (
              <div className="item" key={item.id}>
                <div className="img">
                  <img
                    alt={item?.label}
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item.logoPath}`}
                  />
                </div>
              </div>
            ))}
        </Slider>
      </section>
    </>
  );
}

export default PopularDish;
