import React from "react";

import EventOne from "../../assets/images/nassa/nassa-event-1.jpg";
import EventTwo from "../../assets/images/nassa/nassa-event-2.jpg";

function Events({ data: response, header }) {
  const { data } = response || { data: [] };
  return (
    <>
      <section className="orion_events nassa_events">
        <div className="container mb-5 pb-5">
          <div className="section_title text-center pb-3 mb-4 mt-5">
            {header?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {header?.title}
              </h2>
            )}
          </div>
          <div className="row mb-5">
            {data &&
              data?.length > 0 &&
              data?.map((item, index) => (
                <div
                  className="col-md-6 position-relative orion_card"
                  key={item?.id}
                >
                  <img
                    src={`${process.env.REACT_APP_UPLOAD_URL}${item?.imagePath}`}
                    alt="event1"
                    className="img-fluid round"
                  />
                </div>
              ))}
          </div>
          <div className="text-center">
            <button className="btn btn_primary">View All</button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Events;
