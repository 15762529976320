import React from "react";

import AboutImg from "../../assets/images/abtbanshape.png";
import Icon01 from "../../assets/images/title_icon.png";
import abt01 from "../../assets/images/abtban_01.png";
import abt02 from "../../assets/images/abtban_02.png";
import abt03 from "../../assets/images/abtban_03.png";
import abt04 from "../../assets/images/abtban_04.png";
import abt05 from "../../assets/images/abtban_05.png";

import logoTiktok from "../../assets/images/logo-tiktok.svg";
import logoWhatsapp from "../../assets/images/logo-whatsapp.svg";
import logoFacebook from "../../assets/images/logo-facebook.svg";
import logoInstagram from "../../assets/images/logo-instagram.svg";

import HeroImg from "../../assets/images/orions/hero-bg.jpg";

function AbtBanner({ data }) {
  return (
    <>
      <section
        className="orion_about"
        style={{
          padding: "100px 0px 100px 0px",
          backgroundImage: `url("${
            data?.backgroundPath
              ? `${process.env.REACT_APP_UPLOAD_URL}${data?.backgroundPath}`
              : `${HeroImg}`
          }")`, //url("${HeroImg}")`,
        }}
      >
        <div className="container">
          {/* <div className="right_shape position-absolute">
          <img src={AboutImg} alt="img" />
        </div> */}
          <div className="section_title">
            {/* <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span> */}
            {/* <span
              className="sub_text mb-1"
              data-aos="fade-down"
              data-aos-duration={1500}
            >
              Orion's Belt{" "}
            </span>
            <h2 data-aos="zoom-in" data-aos-duration={1500}>
              Elevate Your Bowling Experience
            </h2> */}
            <div
              className="lead mt-5 mb-5"
              data-aos="fade-up"
              data-aos-duration={1500}
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>
          </div>
          {/* <div className="text-center">
            <ul className="list-inline">
              <li className="list-inline-item pl-2 pr-2">
                <img src={logoTiktok} width={36} height={36} alt="tiktok" />
              </li>
              <li className="list-inline-item pl-2 pr-2">
                <img src={logoWhatsapp} width={36} height={36} alt="whatsapp" />
              </li>
              <li className="list-inline-item pl-2 pr-2">
                <img src={logoFacebook} width={36} height={36} alt="facebook" />
              </li>
              <li className="list-inline-item pl-2 pr-2">
                <img
                  src={logoInstagram}
                  width={36}
                  height={36}
                  alt="instagram"
                />
              </li>
            </ul>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default AbtBanner;
