import React from "react";

import AboutImg from "../../assets/images/nassa/nassa-about.jpg";

function AbtBanner({ data }) {
  return (
    <>
      <section className="nassa_about">
        <div className="container">
          <div className="section_title mb-4">
            {data?.title && (
              <h2 data-aos="zoom-in" data-aos-duration={1500}>
                {data?.title}
              </h2>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <img
                src={`${process.env.REACT_APP_UPLOAD_URL}${data?.imagePath}`}
                alt=""
                className="img-fluid round"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              {data?.content && (
                <div
                  className="lead mt-5"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  dangerouslySetInnerHTML={{ __html: data?.content }}
                ></div>
              )}
            </div>
            <div className="col-4">
              {data?.subTitle && (
                <div
                  className="mt-5 mb-5"
                  data-aos="fade-right"
                  data-aos-duration={1500}
                >
                  <h2
                    className="fancy-text-nassa text-center"
                    style={{ paddingTop: "50px" }}
                  >
                    {data?.subTitle}
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AbtBanner;
