import React from "react";

import Highlight1 from "../../assets/images/nassa/nassa-highlight-1.jpg";
import Highlight2 from "../../assets/images/nassa/nassa-highlight-2.jpg";
import Highlight3 from "../../assets/images/nassa/nassa-highlight-3.jpg";

function AbtBanner({ data: response }) {
  const { data } = response || { data: [] };
  return (
    <>
      <section className="row_inner_am banner_card_section nassa_highlight">
        <div className="container">
          <div className="row" key={data?.id}>
            <div className="col-md-4">
              <div
                className="blog_card"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <img
                  src={`${process.env.REACT_APP_UPLOAD_URL}${data?.imagePath}`}
                  alt={data?.label}
                  className="img-fluid round"
                />
              </div>
              <div className="highlight_text">
                {data?.label && <h3>{data?.label}</h3>}
                {data?.content && (
                  <div
                    className="lead"
                    dangerouslySetInnerHTML={{ __html: data?.content }}
                  ></div>
                )}
              </div>
            </div>
            {/* <div className="col-md-4">
              <div
                className="blog_card"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <img src={Highlight2} alt="img" className="img-fluid round" />
              </div>
              <div className="highlight_text">
                <h3>Ambience</h3>
                <p className="lead">
                  Step into Nassa, where elegance and comfort come together in
                  perfect harmony. Our thoughtfully designed interiors exude
                  sophistication, with a warm and inviting atmosphere that sets
                  the stage for an unforgettable experience. Whether you're
                  enjoying an intimate meal or a special celebration, the
                  ambiance at Nassa enhances the pleasure of every moment.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="blog_card"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <img src={Highlight3} alt="img" className="img-fluid round" />
              </div>
              <div className="highlight_text">
                <h3>Entertainment</h3>
                <p className="lead">
                  At Nassa, we believe dining is more than just about food—it’s
                  about creating an experience. Our carefully curated
                  entertainment offerings, from live performances to elegant
                  background music, provide the perfect accompaniment to your
                  evening. Sit back, relax, and let us elevate your senses with
                  entertainment that complements the indulgence of your meal.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default AbtBanner;
